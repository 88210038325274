import React from 'react';
import PropTypes from 'prop-types';
import { InputHook } from '../InputHook';
import { AcademicLevels } from '../Selects/AcademicLevels';
import { Dependents } from '../Selects/Dependents';
import { MaritalStatus } from '../Selects/MaritalStatus';
import { TypeHousings } from '../Selects/TypeHousings';
import { ButtonSubmit } from '../ButtonSubmit';
import { Container, Form, BoxButton } from './styled';
import { SelectHook } from '../SelectHook';
import { Departments } from '../Selects/Departments';
import { Cities } from '../Selects/Cities';
import { TypeContracts } from '../Selects/TypeContracts';
import { TypeBanks } from '../../components/Selects/TypeBanks';
import { AccountTypes } from '../../components/Selects/AccountTypes';
import { InsuranceTypes } from '../Selects/InsuranceTypes';

export const EconomicDataRegisterV = ({
    data: { banner, errors, loading, loadEconomy, format, values },
    handleBlur,
    handleChange,
    handleSubmit,
    userParameters,
}) => (
    <Container>
        {banner}
        <Form>
            {!!format?.ufSons && (
                <SelectHook
                    required
                    nameAtt="title"
                    name="psSons"
                    title="Hijos"
                    testID="da880ec0-950c-11ee-82a9-3dc99027d51b"
                    titleModal="Seleccionar Opción"
                    value={values?.payrollStaff?.psSons}
                    error={errors.psSons}
                    handleSelect={(e) => handleChange(e, 2)}
                    data={[
                        { psSons: 1, title: 'SI' },
                        { psSons: 2, title: 'NO' },
                    ]}
                />
            )}
            {!!format?.ufWeight && (
                <InputHook
                    testID="a2a6ce03-e65a-4c6d-80ad-b13b5b188317"
                    title="Peso en Kg"
                    returnKeyType="next"
                    keyboardType="numeric"
                    error={errors?.psWeight}
                    value={values?.payrollStaff?.psWeight}
                    name="psWeight"
                    numeric
                    onBlur={handleBlur}
                    onChangeText={(e) => handleChange(e, 2)}
                />
            )}
            {!!format?.ufHeight && (
                <InputHook
                    testID="0f939df0-950d-11ee-b407-0f3304c89b6b"
                    title="Estatura"
                    returnKeyType="next"
                    keyboardType="numeric"
                    error={errors?.psHeight}
                    value={values?.payrollStaff?.psHeight}
                    name="psHeight"
                    onBlur={handleBlur}
                    onChangeText={(e) => handleChange(e, 2)}
                />
            )}
            {!!format?.ufTypHou && (
                <TypeHousings
                    testID="ed3738a3-b92a-45fc-913f-f13ab902ca09"
                    error={errors?.thId}
                    value={values?.payrollResidence?.thId}
                    handleSelect={(e) => handleChange(e, 1)}
                />
            )}
            {!!format?.ufDependents && (
                <Dependents
                    testID="b4d2ea78-4497-4392-8faa-1e0fc399e199"
                    error={errors?.dId}
                    value={values?.payrollResidence?.dId}
                    handleSelect={(e) => handleChange(e, 1)}
                />
            )}
            {!!format?.ufAcaLev && (
                <AcademicLevels
                    testID="d5c6fa8a-0b57-49a8-a623-561641150a44"
                    error={errors?.alId}
                    value={values?.payrollResidence?.alId}
                    handleSelect={(e) => handleChange(e, 1)}
                />
            )}
            {!!format?.ufMarSta && (
                <MaritalStatus
                    testID="c8a786e9-dcd0-4fa7-894d-0081cc8cd46c"
                    error={errors?.msId}
                    value={values?.payrollResidence?.msId}
                    handleSelect={(e) => handleChange(e, 1)}
                />
            )}
            <TypeBanks
                testID="c46f8d7c-7c23-41fc-b3a1-58dff0801ab7"
                error={errors?.tbId}
                value={values?.payrollBank?.tbId}
                handleSelect={(e) => handleChange(e, 4)}
            />
            <Cities
                testID="0ac8647b-5a7f-4606-8a14-12e89d0861fa"
                title="Ciudad sucursal bancaria *"
                name="cityId"
                error={errors?.cityId}
                value={values?.payrollBank?.cityId}
                handleSelect={(e) => handleChange(e, 4)}
            />
            <AccountTypes
                testID="8a5c2134-cd9b-4899-a835-2ef770663e9f"
                error={errors?.atId}
                value={values?.payrollBank?.atId}
                handleSelect={(e) => handleChange(e, 4)}
            />
            <InputHook
                testID="c576a9f3-fa54-4e8a-93a8-bf72a3724ba6"
                title="Número de Cuenta Bancaria *"
                keyboardType="numeric"
                error={errors?.pbAccNum}
                value={values?.payrollBank?.pbAccNum}
                name="pbAccNum"
                required
                range={[3, 30]}
                onBlur={handleBlur}
                onChangeText={(e) => handleChange(e, 4)}
            />
            {userParameters?.sarlaft === 1 && format?.ufSarlaft === 1 && (
                <>
                    <InputHook
                        testID="a87e6ffb-a29a-42be-b82d-87011a5d50af"
                        title="Activos"
                        returnKeyType="next"
                        keyboardType="numeric"
                        error={errors?.pwActive}
                        value={
                            values?.payrollWork?.pwActive === 0
                                ? '0'
                                : values?.payrollWork?.pwActive
                        }
                        name="pwActive"
                        numeric
                        format
                        range={[1, 15]}
                        onBlur={handleBlur}
                        onChangeText={handleChange}
                    />
                    <InputHook
                        testID="27cc8660-950e-11ee-b4de-e1121d618a2c"
                        title="Pasivos"
                        returnKeyType="next"
                        keyboardType="numeric"
                        error={errors?.pwPassive}
                        value={
                            values?.payrollWork?.pwPassive === 0
                                ? '0'
                                : values?.payrollWork?.pwPassive
                        }
                        name="pwPassive"
                        numeric
                        format
                        range={[1, 15]}
                        onBlur={handleBlur}
                        onChangeText={handleChange}
                    />
                    <SelectHook
                        required
                        nameAtt="title"
                        name="pwHolPubOff"
                        testID="37234218-652e-470b-8951-e5bc7f1a1d00"
                        title="Ejerce cargos públicos"
                        titleModal="Seleccionar Opción"
                        value={values?.payrollWork?.pwHolPubOff}
                        error={errors.pwHolPubOff}
                        handleSelect={(e) => handleChange(e)}
                        data={[
                            { pwHolPubOff: 1, title: 'SI' },
                            { pwHolPubOff: 2, title: 'NO' },
                        ]}
                    />
                    <SelectHook
                        required
                        nameAtt="title"
                        name="pwHasPubRec"
                        testID="c74913c0-950e-11ee-a6f6-9f9f0b11a297"
                        title="Tiene reconocimiento público"
                        titleModal="Seleccionar Opción"
                        value={values?.payrollWork?.pwHasPubRec}
                        error={errors.pwHasPubRec}
                        handleSelect={(e) => handleChange(e)}
                        disabled={values?.payrollWork?.pwHolPubOff === 2}
                        data={[
                            { pwHasPubRec: 1, title: 'SI' },
                            { pwHasPubRec: 2, title: 'NO' },
                        ]}
                    />
                    <SelectHook
                        required
                        nameAtt="title"
                        name="pwHasRelPubExp"
                        testID="9da1c72d-a3c0-45c0-836d-c74a455323ec"
                        title="Tiene relación con una persona expuesta públicamente"
                        titleModal="Seleccionar Opción"
                        value={values?.payrollWork?.pwHasRelPubExp}
                        error={errors.pwHasRelPubExp}
                        disabled={values?.payrollWork?.pwHolPubOff === 2}
                        handleSelect={(e) => handleChange(e)}
                        data={[
                            { pwHasRelPubExp: 1, title: 'SI' },
                            { pwHasRelPubExp: 2, title: 'NO' },
                        ]}
                    />
                    <SelectHook
                        required
                        nameAtt="title"
                        name="pwManPubRes"
                        testID="f7e2c9e0-950e-11ee-914b-4db0a973c8a9"
                        title="Maneja recursos públicos"
                        titleModal="Seleccionar Opción"
                        value={values?.payrollWork?.pwManPubRes}
                        error={errors.pwManPubRes}
                        handleSelect={(e) => handleChange(e)}
                        data={[
                            { pwManPubRes: 1, title: 'SI' },
                            { pwManPubRes: 2, title: 'NO' },
                        ]}
                    />
                </>
            )}
            {!!format?.ufOthInc && (
                <InputHook
                    testID="7f15bdc9-9c3a-40dd-b41f-3b8631e4c06b"
                    title="Otros ingresos (Opcional)"
                    returnKeyType="next"
                    keyboardType="numeric"
                    error={errors?.pwOthInc}
                    value={
                        values?.payrollWork?.pwOthInc === 0
                            ? '0'
                            : values?.payrollWork?.pwOthInc
                    }
                    name="pwOthInc"
                    numeric
                    format
                    range={[1, 15]}
                    onBlur={handleBlur}
                    onChangeText={handleChange}
                />
            )}
            {!!format?.ufFinExp && (
                <InputHook
                    testID="eb193dab-2db9-4d49-8fa1-c8dfc4d35ca8"
                    title="Gastos financieros (Opcional)"
                    returnKeyType="next"
                    keyboardType="numeric"
                    error={errors?.pwFinExp}
                    value={
                        values?.payrollWork?.pwFinExp === 0
                            ? '0'
                            : values?.payrollWork?.pwFinExp
                    }
                    name="pwFinExp"
                    numeric
                    format
                    range={[1, 15]}
                    onBlur={handleBlur}
                    onChangeText={handleChange}
                />
            )}
            {!!format?.ufPerExp && (
                <InputHook
                    testID="74f99ae0-950e-11ee-b352-2f5b48fcd277"
                    title="Gastos personales (Opcional)"
                    returnKeyType="next"
                    keyboardType="numeric"
                    error={errors?.pwPerExp}
                    value={
                        values?.payrollWork?.pwPerExp === 0
                            ? '0'
                            : values?.payrollWork?.pwPerExp
                    }
                    name="pwPerExp"
                    numeric
                    format
                    range={[1, 15]}
                    onBlur={handleBlur}
                    onChangeText={handleChange}
                />
            )}
            {!!format?.ufEconomyActivities && (
                <>
                    <SelectHook
                        required
                        nameAtt="title"
                        name="peaActivity"
                        testID="f2e2d8fe-ab76-4059-be05-32f6409d6a60"
                        title="Actividad principal *"
                        titleModal="Seleccionar Opción"
                        value={values?.payrollEconomyActivities?.peaActivity}
                        error={errors.peaActivity}
                        handleSelect={(e) => handleChange(e, 3)}
                        data={[
                            { peaActivity: 'EMPLEADO', title: 'EMPLEADO' },
                            { peaActivity: 'INDEPENDIENTE', title: 'INDEPENDIENTE' },
                            { peaActivity: 'PENSIONADO', title: 'PENSIONADO' },
                            { peaActivity: 'ESTUDIANTE', title: 'ESTUDIANTE' },
                        ]}
                    />
                    {values?.payrollEconomyActivities?.peaActivity === 'EMPLEADO' && (
                        <>
                            <InputHook
                                testID="5476a0e0-9510-11ee-8647-0d7ba8b881b0"
                                title="Nombre de la empresa"
                                error={errors?.peaCompanyName}
                                value={values?.payrollEconomyActivities?.peaCompanyName}
                                name="peaCompanyName"
                                onBlur={handleBlur}
                                onChangeText={(e) => handleChange(e, 3)}
                            />
                            <InputHook
                                testID="ac42277c-6c24-4877-909c-1f74d1de9fa9"
                                title="Dirección de la empresa"
                                error={errors?.peaAddress}
                                value={values?.payrollEconomyActivities?.peaAddress}
                                name="peaAddress"
                                onBlur={handleBlur}
                                onChangeText={(e) => handleChange(e, 3)}
                            />
                            <SelectHook
                                required
                                nameAtt="title"
                                name="peaTypeCompany"
                                testID="5e2d8180-9510-11ee-acb8-835e5ae09bcd"
                                title="Tipo de empresa"
                                titleModal="Seleccionar Opción"
                                value={values?.payrollEconomyActivities?.peaTypeCompany}
                                error={errors.peaTypeCompany}
                                handleSelect={(e) => handleChange(e, 3)}
                                data={[
                                    { peaTypeCompany: 'PRIVADA', title: 'PRIVADA' },
                                    { peaTypeCompany: 'PÚBLICA', title: 'PÚBLICA' },
                                    { peaTypeCompany: 'MIXTA', title: 'MIXTA' },
                                ]}
                            />
                            <InputHook
                                testID="41b0fd85-161d-4cb1-a255-fd27a8b5d72b"
                                title="Teléfono laboral"
                                error={errors?.peaWorkPhone}
                                value={values?.payrollEconomyActivities?.peaWorkPhone}
                                name="peaWorkPhone"
                                required
                                numeric
                                onBlur={handleBlur}
                                onChangeText={(e) => handleChange(e, 3)}
                            />
                            <InputHook
                                testID="6e4783e0-9510-11ee-903c-8f4720fd2176"
                                title="Correo electrónico laboral"
                                error={errors?.peaEmail}
                                value={values?.payrollEconomyActivities?.peaEmail}
                                required
                                name="peaEmail"
                                email
                                onBlur={handleBlur}
                                onChangeText={(e) => handleChange(e, 3)}
                            />
                            <Departments
                                testID="d120e769-43c0-439b-8207-8a51f35fe98a"
                                title="Departamento laboral"
                                error={errors?.dIdDepartment}
                                value={values?.payrollEconomyActivities?.dIdDepartment}
                                required
                                handleSelect={(e) => handleChange(e, 3)}
                            />
                            <Cities
                                testID="7786c380-9510-11ee-a9ed-d1d29ccd687b"
                                title="Ciudad laboral"
                                error={errors?.cId}
                                value={values?.payrollEconomyActivities?.cId}
                                required
                                handleSelect={(e) => handleChange(e, 3)}
                            />
                            <InputHook
                                testID="7d757890-9510-11ee-9746-2db6ca52f7c9"
                                title="Cargo actual"
                                error={errors?.peaPosition}
                                value={values?.payrollEconomyActivities?.peaPosition}
                                name="peaPosition"
                                letters
                                required
                                onBlur={handleBlur}
                                onChangeText={(e) => handleChange(e, 3)}
                            />
                            <TypeContracts
                                testID="36cbd47c-c899-45cf-982b-1c0ce8ce362a"
                                title="Tipo contrato"
                                error={errors?.tcId}
                                value={values?.payrollEconomyActivities?.tcId}
                                required
                                handleSelect={(e) => handleChange(e, 3)}
                            />
                            <InputHook
                                testID="86ad6530-9510-11ee-8609-c9d09a2867f0"
                                title="Antigüedad en meses"
                                error={errors?.peaAntiquity}
                                value={values?.payrollEconomyActivities?.peaAntiquity}
                                name="peaAntiquity"
                                numeric
                                required
                                onBlur={handleBlur}
                                onChangeText={(e) => handleChange(e, 3)}
                            />
                        </>
                    )}
                    {values?.payrollEconomyActivities?.peaActivity ===
            'INDEPENDIENTE' && (
                        <>
                            <InputHook
                                testID="5c3adf03-dfd2-425d-87b4-708111e699f5"
                                title="Actividad comercial"
                                error={errors?.peaBussiness}
                                value={values?.payrollEconomyActivities?.peaBussiness}
                                name="peaBussiness"
                                letters
                                required
                                onBlur={handleBlur}
                                onChangeText={(e) => handleChange(e, 3)}
                            />
                            <InputHook
                                testID="92e28c40-9510-11ee-80ec-67d842949fd6"
                                title="Antigüedad en meses de actividad comercial"
                                error={errors?.peaAntiquityIndependents}
                                value={
                                    values?.payrollEconomyActivities?.peaAntiquityIndependents
                                }
                                name="peaAntiquityIndependents"
                                numeric
                                required
                                onBlur={handleBlur}
                                onChangeText={(e) => handleChange(e, 3)}
                            />
                            <SelectHook
                                required
                                nameAtt="title"
                                name="peaChamberCommerce"
                                testID="51f480c9-fd76-42b5-84f1-02ffd0dbd1e0"
                                title="¿Tiene cámara de comercio?"
                                titleModal="Seleccionar Opción"
                                value={values?.payrollEconomyActivities?.peaChamberCommerce}
                                error={errors.peaChamberCommerce}
                                handleSelect={(e) => handleChange(e, 3)}
                                data={[
                                    { peaChamberCommerce: 1, title: 'SI' },
                                    { peaChamberCommerce: 2, title: 'NO' },
                                ]}
                            />
                            <SelectHook
                                required
                                nameAtt="title"
                                name="peaRUT"
                                testID="b0abf9a0-9510-11ee-849f-0763bd85a5d9"
                                title="¿Tiene RUT?"
                                titleModal="Seleccionar Opción"
                                value={values?.payrollEconomyActivities?.peaRUT}
                                error={errors.peaRUT}
                                handleSelect={(e) => handleChange(e, 3)}
                                data={[
                                    { peaRUT: 1, title: 'SI' },
                                    { peaRUT: 2, title: 'NO' },
                                ]}
                            />
                        </>
                    )}
                    {values?.payrollEconomyActivities?.peaActivity === 'PENSIONADO' && (
                        <>
                            <InputHook
                                testID="15372a20-9516-11ee-bc44-cd310bb76c70"
                                title="Fondo de pensión"
                                error={errors?.peaPensionFund}
                                value={values?.payrollEconomyActivities?.peaPensionFund}
                                name="peaPensionFund"
                                letters
                                required
                                onBlur={handleBlur}
                                onChangeText={(e) => handleChange(e, 3)}
                            />
                            <SelectHook
                                required
                                nameAtt="title"
                                name="peaTypePension"
                                testID="56c79452-7143-46d9-a6fa-5e3d11e4de06"
                                title="Tipo de pensión"
                                titleModal="Seleccionar Opción"
                                value={values?.payrollEconomyActivities?.peaTypePension}
                                error={errors.peaTypePension}
                                handleSelect={(e) => handleChange(e, 3)}
                                data={[
                                    { peaTypePension: 'VEJEZ', title: 'VEJEZ' },
                                    { peaTypePension: 'INVALIDEZ', title: 'INVALIDEZ' },
                                    { peaTypePension: 'SOBREVIVENCIA', title: 'SOBREVIVENCIA' },
                                    {
                                        peaTypePension: 'RIESGOS LABORALES',
                                        title: 'RIESGOS LABORALES',
                                    },
                                ]}
                            />
                        </>
                    )}
                    {values?.payrollEconomyActivities?.peaActivity === 'ESTUDIANTE' && (
                        <>
                            <InputHook
                                testID="ca6e5db0-9510-11ee-b66d-4536041e5971"
                                title="Universidad"
                                error={errors?.peaUniversity}
                                value={values?.payrollEconomyActivities?.peaUniversity}
                                name="peaUniversity"
                                letters
                                required
                                onBlur={handleBlur}
                                onChangeText={(e) => handleChange(e, 3)}
                            />
                            <SelectHook
                                required
                                nameAtt="title"
                                name="peaGenerateIncome"
                                title="¿Genera algún ingreso?"
                                testID="5bac5efa-514a-49e3-8efd-f43c820889c9"
                                titleModal="Seleccionar Opción"
                                value={values?.payrollEconomyActivities?.peaGenerateIncome}
                                error={errors.peaGenerateIncome}
                                handleSelect={(e) => handleChange(e, 3)}
                                data={[
                                    { peaGenerateIncome: 1, title: 'SI' },
                                    { peaGenerateIncome: 2, title: 'NO' },
                                ]}
                            />
                        </>
                    )}
                    <InputHook
                        testID="30ed0b6c-90e8-4032-ac63-324d841f26a2"
                        title="Codigo CIUU"
                        keyboardType="numeric"
                        returnKeyType="next"
                        error={errors?.pwCIIU}
                        value={values?.payrollWork?.pwCIIU === null || values?.payrollWork?.pwCIIU === '' ? '' : values?.payrollWork?.pwCIIU}
                        name="pwCIIU"
                        onBlur={handleBlur}
                        onChangeText={handleChange}
                    />
                </>
            )}
            {!!format?.ufInsuranceEntities && (
                <InsuranceTypes
                    testID="354645c2134-cd9b-4899-a835-2ef770645432"
                    error={errors?.ieId}
                    value={values?.payrollLifeInsurance?.ieId}
                    handleSelect={e => handleChange(e, 5)}
                />
            )}
            <BoxButton>
                <ButtonSubmit
                    testID="181ec7bf-bafd-4301-b0e3-9977b42b77e4"
                    onPress={handleSubmit}
                    title="Continuar"
                    loading={loading || loadEconomy}
                />
            </BoxButton>
        </Form>
    </Container>
);

EconomicDataRegisterV.propTypes = {
    data: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    userParameters: PropTypes.object,
};