import { selectClientName } from './utils'

//Colores
const themes = {
    mili: {
        primaryC: '#009587',
        notification: '#ECECEC',
        notificationSeen: '#000000',
        variantPrimaryC: '#364654',
        backgroundColor: '#F3FFFE',
        secondaryC: '#F15A24',
        variantSecondaryC: '#FFAC00',
        alternateC: '#',
        backgroundC: '#FFFFFF',
        surfaceC: '#FFFFFF',
        errorC: '#E40101',
        errorA: '#d33c3c',
        successC: '#94bf9b',
        onBackgroundC: '#A5A5A5',
        onBgVariantC: '#423148',
        onSurfaceC: '#14110F',
        onSurfaceV: '#a4a4a4',
        onVariantSurfC: '#BBBBBB',
        onButtonC: '#F7AF69',
        grayLight: '#E1E1E1',
        backgroundP: '#F8FFFF',
        backgroundV: '#F8FFFF',
        nameApp: 'Mili',
        sLogan: 'Tu Crédito de Confianza',
        // --------- Nuevos Colores para Temas
        PTheme: {
            // Interface
            PColor: '#009587',
            PVColor: '#364654',
            SColor: '#F15A24',
            SVColor: '#FFAC00',
            AColor: '#000000',
            PLColor: '#E1E1E1',
            PLAColor: '#898989',
            // Background Color
            BGColor: '#FFFFFF',
            BGSColor: '#F8FFFF',
            BGPColor: '#F8F8F8',
            BGVColor: '#A5A5A5',
            BGAColor: '#423148',
            // Font Color
            SFColor: '#14110F',
            SFSColor: '#a4a4a4',
            SFAColor: '#FFFFFF',
            SFVColor: '#BBBBBB',
            // Information Color
            EColor: '#E40101',
            EAColor: '#d33c3c',
            SCColor: '#94bf9b',
            WColor: '#FE9A00',
            // Special Color
            BTNColor: '#F7AF69',
            NoAuthorize: '#364654',
            White: '#FFFFFF',
        }
    },
    finnvo: {
        primaryC: '#00AF98',
        variantPrimaryC: '#364654',
        notification: '#ECECEC',
        notificationSeen: '#000000',
        secondaryC: '#b1ddd9',
        backgroundColor: '#b1ddd9',
        variantSecondaryC: '#FFAC00',
        alternateC: '#',
        backgroundC: '#FFFFFF',
        surfaceC: '#FFFFFF',
        errorC: '#E40101',
        onBackgroundC: '#A5A5A5',
        onBgVariantC: '#423148',
        onSurfaceC: '#14110F',
        onVariantSurfC: '#BBBBBB',
        onButtonC: '#F7AF69',
        grayLight: '#E1E1E1',
        backgroundP: '#FFFFFF',
        nameApp: 'Finnvo',
        sLogan: 'Siempre Tu Aliado',
        // --------- Nuevos Colores para Temas
        PTheme: {
            // Interface
            PColor: '#00AF98',
            PVColor: '#364654',
            SColor: '#b1ddd9',
            SVColor: '#FFAC00',
            AColor: '#000000',
            PLColor: '#E1E1E1',
            PLAColor: '#898989',

            // Background Color
            BGColor: '#FFFFFF',
            BGSColor: '#FFFFFF',
            BGPColor: '#F8F8F8',
            BGVColor: '#A5A5A5',
            BGAColor: '#423148',

            // Font Color
            SFColor: '#14110F',
            SFSColor: '#a4a4a4',
            SFAColor: '#FFFFFF',
            SFVColor: '#BBBBBB',

            // Information Color
            EColor: '#E40101',
            EAColor: '#d33c3c',
            SCColor: '#94bf9b',

            // Special Color
            BTNColor: '#F7AF69',
            NoAuthorize: '#364654',
            White: '#FFFFFF',
        }
    },
    credipress: {
        primaryC: '#4147AF',
        variantPrimaryC: '#4147AF',
        backgroundColor: '#4147AF',
        notification: '#ECECEC',
        notificationSeen: '#000000',
        secondaryC: '#FFAC00',
        variantSecondaryC: '#FFAC00',
        alternateC: '#',
        backgroundC: '#FFFFFF',
        surfaceC: '#FFFFFF',
        errorC: '#E40101',
        onBackgroundC: '#A5A5A5',
        onBgVariantC: '#423148',
        onSurfaceC: '#14110F',
        onVariantSurfC: '#BBBBBB',
        onButtonC: '#F7AF69',
        grayLight: '#E1E1E1',
        backgroundP: '#FFFFFF',
        nameApp: 'CrediPress',
        sLogan: 'Tu Crédito 100% Digital',
        errorA: '#d33c3c',
        successC: '#94bf9b',
        onSurfaceV: '#a4a4a4',
        backgroundV: '#FFFFFF',
        // --------- Nuevos Colores para Temas
        PTheme: {
            // Interface
            PColor: '#4147AF',
            PVColor: '#4147AF',
            SColor: '#F15A24',
            SVColor: '#FFAC00',
            AColor: '#000000',
            PLColor: '#E1E1E1',
            PLAColor: '#898989',

            // Background Color
            BGColor: '#FFFFFF',
            BGSColor: '#FFFFFF',
            BGPColor: '#F8F8F8',
            BGVColor: '#A5A5A5',
            BGAColor: '#423148',

            // Font Color
            SFColor: '#14110F',
            SFSColor: '#a4a4a4',
            SFAColor: '#FFFFFF',
            SFVColor: '#BBBBBB',

            // Information Color
            EColor: '#E40101',
            EAColor: '#d33c3c',
            SCColor: '#94bf9b',

            // Special Color
            BTNColor: '#F7AF69',
            NoAuthorize: '#364654',
            White: '#FFFFFF',
        }
    },
    creditoo: {
        primaryC: '#0097D7',
        variantPrimaryC: '#30E8D8',
        secondaryC: '#cdeae6',
        notification: '#ECECEC',
        notificationSeen: '#000000',
        backgroundColor: '#E8F8FF',
        variantSecondaryC: '#423148',
        alternateC: '#ffb23f',
        backgroundC: '#FFFFFF',
        surfaceC: '#FFFFFF',
        errorC: '#E40101',
        errorA: '#d33c3c',
        successC: '#94bf9b',
        onBackgroundC: '#A5A5A5',
        onBgVariantC: '#423148',
        onSurfaceC: '#14110F',
        onSurfaceV: '#a4a4a4',
        onVariantSurfC: '#BBBBBB',
        onButtonC: '#F7AF69',
        grayLight: '#E1E1E1',
        backgroundP: '#F8FFFF',
        backgroundV: '#F8FFFF',
        nameApp: 'Mili',
        sLogan: 'Tu Crédito de Confianza',
        // --------- Nuevos Colores para Temas
        PTheme: {
            // Interface
            PColor: '#0097D7',
            PVColor: '#30E8D8',
            SColor: '#cdeae6',
            SVColor: '#423148',
            AColor: '#000000',
            PLColor: '#E1E1E1',
            PLAColor: '#898989',

            // Background Color
            BGColor: '#FFFFFF',
            BGSColor: '#F8FFFF',
            BGPColor: '#F8F8F8',
            BGVColor: '#A5A5A5',
            BGAColor: '#423148',

            // Font Color
            SFColor: '#14110F',
            SFSColor: '#a4a4a4',
            SFAColor: '#FFFFFF',
            SFVColor: '#BBBBBB',

            // Information Color
            EColor: '#E40101',
            EAColor: '#d33c3c',
            SCColor: '#94bf9b',
            WColor: '#FE9A00',

            // Special Color
            BTNColor: '#F7AF69',
            NoAuthorize: '#364654',
            White: '#FFFFFF',
        }
    },
    fibanca: {
        primaryC: '#203576',
        variantPrimaryC: '#1D1D1B',
        secondaryC: '#FFCE47',
        notification: '#ECECEC',
        notificationSeen: '#000000',
        backgroundColor: '#ECEEFF',
        variantSecondaryC: '#EFDCBB',
        alternateC: '#',
        backgroundC: '#FFFFFF',
        surfaceC: '#FFFFFF',
        errorC: '#E40101',
        onBackgroundC: '#A5A5A5',
        onBgVariantC: '#423148',
        onSurfaceC: '#14110F',
        onVariantSurfC: '#BBBBBB',
        onButtonC: '#F7AF69',
        grayLight: '#E1E1E1',
        backgroundP: '#FFFFFF',
        nameApp: 'FiBanca',
        sLogan: 'Financiamiento de Banca',
        errorA: '#d33c3c',
        successC: '#94bf9b',
        onSurfaceV: '#a4a4a4',
        backgroundV: '#F8FFFF',
        // --------- Nuevos Colores para Temas
        PTheme: {
            // Interface
            PColor: '#203576',
            PVColor: '#1D1D1B',
            SColor: '#FFCE47',
            SVColor: '#EFDCBB',
            AColor: '#000000',
            PLColor: '#E1E1E1',
            PLAColor: '#898989',

            // Background Color
            BGColor: '#FFFFFF',
            BGSColor: '#FFFFFF',
            BGPColor: '#F8F8F8',
            BGVColor: '#A5A5A5',
            BGAColor: '#423148',

            // Font Color
            SFColor: '#14110F',
            SFSColor: '#a4a4a4',
            SFAColor: '#FFFFFF',
            SFVColor: '#BBBBBB',

            // Information Color
            EColor: '#E40101',
            EAColor: '#d33c3c',
            SCColor: '#94bf9b',

            // Special Color
            BTNColor: '#F7AF69',
            NoAuthorize: '#364654',
            White: '#FFFFFF',
        }
    },
    bancaliv: {
        primaryC: '#79114A',
        backgroundColor: '#F4C1E1',
        variantPrimaryC: '#364654',
        notification: '#ECECEC',
        notificationSeen: '#000000',
        secondaryC: '#A1005D',
        variantSecondaryC: '#FFAC00',
        alternateC: '#',
        backgroundC: '#FFFFFF',
        surfaceC: '#FFFFFF',
        errorC: '#E40101',
        onBackgroundC: '#A5A5A5',
        onBgVariantC: '#423148',
        onSurfaceC: '#14110F',
        onVariantSurfC: '#BBBBBB',
        onButtonC: '#F7AF69',
        grayLight: '#E1E1E1',
        backgroundP: '#FFFFFF',
        nameApp: 'Bancaliv',
        sLogan: 'Pensando en Ti',
        errorA: '#d33c3c',
        successC: '#94bf9b',
        onSurfaceV: '#a4a4a4',
        backgroundV: '#FFFFFF',
        // --------- Nuevos Colores para Temas
        PTheme: {
            // Interface
            PColor: '#79114A',
            PVColor: '#364654',
            SColor: '#F15A24',
            SVColor: '#FFAC00',
            AColor: '#000000',
            PLColor: '#E1E1E1',
            PLAColor: '#898989',

            // Background Color
            BGColor: '#FFFFFF',
            BGSColor: '#FFFFFF',
            BGPColor: '#FFFFFF',
            BGVColor: '#A5A5A5',
            BGAColor: '#423148',
            // Font Color
            SFColor: '#14110F',
            SFSColor: '#a4a4a4',
            SFAColor: '#FFFFFF',
            SFVColor: '#BBBBBB',

            // Information Color
            EColor: '#E40101',
            EAColor: '#d33c3c',
            SCColor: '#94bf9b',

            // Special Color
            BTNColor: '#F7AF69',
            NoAuthorize: '#364654',
            White: '#FFFFFF',
        }
    },
    tusolucion: {
        primaryC: '#2dac67',
        variantPrimaryC: '#2f318d',
        secondaryC: '#1571ba',
        backgroundColor: '#E4FFEE',
        notification: '#ECECEC',
        notificationSeen: '#000000',
        variantSecondaryC: '#FFAC00',
        alternateC: '#',
        backgroundC: '#FFFFFF',
        surfaceC: '#FFFFFF',
        errorC: '#E40101',
        onBackgroundC: '#A5A5A5',
        onBgVariantC: '#423148',
        onSurfaceC: '#14110F',
        onVariantSurfC: '#BBBBBB',
        onButtonC: '#F7AF69',
        grayLight: '#E1E1E1',
        backgroundP: '#FFFFFF',
        nameApp: 'Tu Solución',
        sLogan: 'Avanzamos Contigo',
        errorA: '#d33c3c',
        successC: '#94bf9b',
        onSurfaceV: '#a4a4a4',
        backgroundV: '#FFFFFF',

        // --------- Nuevos Colores para Temas
        PTheme: {
            // Interface
            PColor: '#2dac67',
            PVColor: '#2f318d',
            SColor: '#1571ba',
            SVColor: '#FFAC00',
            AColor: '#000000',
            PLColor: '#E1E1E1',
            PLAColor: '#898989',

            // Background Color
            BGColor: '#FFFFFF',
            BGSColor: '#FFFFFF',
            BGPColor: '#F8F8F8',
            BGVColor: '#A5A5A5',
            BGAColor: '#423148',

            // Font Color
            SFColor: '#14110F',
            SFSColor: '#a4a4a4',
            SFAColor: '#FFFFFF',
            SFVColor: '#BBBBBB',

            // Information Color
            EColor: '#E40101',
            EAColor: '#d33c3c',
            SCColor: '#94bf9b',

            // Special Color
            BTNColor: '#F7AF69',
            NoAuthorize: '#364654',
            White: '#FFFFFF',
        }
    },
    liberate: {
        primaryC: '#0B2442',
        variantPrimaryC: '#0B2442',
        notification: '#ECECEC',
        notificationSeen: '#000000',
        secondaryC: '#FFF400',
        backgroundColor: '#E7EDFA',
        variantSecondaryC: '#4147E8',
        alternateC: '#',
        backgroundC: '#FFFFFF',
        surfaceC: '#FFFFFF',
        errorC: '#E40101',
        onBackgroundC: '#A5A5A5',
        onBgVariantC: '#423148',
        onSurfaceC: '#14110F',
        onVariantSurfC: '#BBBBBB',
        onButtonC: '#F7AF69',
        grayLight: '#E1E1E1',
        backgroundP: '#FFFFFF',
        nameApp: 'Liberate',
        sLogan: 'Colombia',
        errorA: '#d33c3c',
        successC: '#94bf9b',
        onSurfaceV: '#a4a4a4',
        backgroundV: '#FFFFFF',

        // --------- Nuevos Colores para Temas
        PTheme: {
            // Interface
            PColor: '#0B2442',
            PVColor: '#0B2442',
            SColor: '#FFF400',
            SVColor: '#4147E8',
            AColor: '#000000',
            PLColor: '#E1E1E1',
            PLAColor: '#898989',

            // Background Color
            BGColor: '#FFFFFF',
            BGSColor: '#FFFFFF',
            BGPColor: '#F8F8F8',
            BGVColor: '#A5A5A5',
            BGAColor: '#423148',

            // Font Color
            SFColor: '#14110F',
            SFSColor: '#a4a4a4',
            SFAColor: '#FFFFFF',
            SFVColor: '#BBBBBB',

            // Information Color
            EColor: '#E40101',
            EAColor: '#d33c3c',
            SCColor: '#94bf9b',

            // Special Color
            BTNColor: '#F7AF69',
            NoAuthorize: '#364654',
            White: '#FFFFFF',
        }
    },
    nauw: {
        primaryC: '#56AC47',
        variantPrimaryC: '#ddffed',
        backgroundColor: '#DDFFED',
        notification: '#ECECEC',
        notificationSeen: '#000000',
        secondaryC: '#1571ba',
        variantSecondaryC: '#FFAC00',
        alternateC: '#',
        backgroundC: '#F8FFFF',
        surfaceC: '#FFFFFF',
        errorC: '#E40101',
        onBackgroundC: '#A5A5A5',
        onBgVariantC: '#423148',
        onSurfaceC: '#14110F',
        onVariantSurfC: '#BBBBBB',
        onButtonC: '#F7AF69',
        grayLight: '#E1E1E1',
        backgroundP: '#F8FFFF',
        nameApp: 'Nauw Colombia',
        sLogan: '',
        errorA: '#d33c3c',
        successC: '#94bf9b',
        onSurfaceV: '#a4a4a4',
        backgroundV: '#F8FFFF',

        // --------- Nuevos Colores para Temas
        PTheme: {
            // Interface
            PColor: '#56AC47',
            PVColor: '#56AC47',
            SColor: '#1571ba',
            SVColor: '#FFAC00',
            AColor: '#000000',
            PLColor: '#E1E1E1',
            PLAColor: '#898989',

            // Background Color
            BGColor: '#FFFFFF',
            BGSColor: '#F8FFFF',
            BGPColor: '#F8FFFF',
            BGVColor: '#A5A5A5',
            BGAColor: '#423148',

            // Font Color
            SFColor: '#14110F',
            SFSColor: '#a4a4a4',
            SFAColor: '#FFFFFF',
            SFVColor: '#BBBBBB',

            // Information Color
            EColor: '#E40101',
            EAColor: '#d33c3c',
            SCColor: '#94bf9b',

            // Special Color
            BTNColor: '#F7AF69',
            NoAuthorize: '#364654',
            White: '#FFFFFF',
        }
    },
    fie: {
        primaryC: '#0071c0',
        variantPrimaryC: '#0071c0',
        backgroundColor: '#EDF9FF',
        secondaryC: '#ffb23f',
        variantSecondaryC: '#423148',
        notification: '#ECECEC',
        notificationSeen: '#000000',
        alternateC: '#ffb23f',
        backgroundC: '#FFFFFF',
        surfaceC: '#FFFFFF',
        errorC: '#E40101',
        errorA: '#d33c3c',
        successC: '#94bf9b',
        onBackgroundC: '#A5A5A5',
        onBgVariantC: '#423148',
        onSurfaceC: '#14110F',
        onSurfaceV: '#a4a4a4',
        onVariantSurfC: '#BBBBBB',
        onButtonC: '#F7AF69',
        grayLight: '#E1E1E1',
        backgroundP: '#FFFFFF',
        backgroundV: '#edf9ff',
        nameApp: 'Fie',
        sLogan: 'Financiamos tus sueños',
        // --------- Nuevos Colores para Temas
        PTheme: {
            // Interface
            PColor: '#0071c0',
            PVColor: '#0071c0',
            SColor: '#ffb23f',
            SVColor: '#423148',
            AColor: '#000000',
            PLColor: '#E1E1E1',
            PLAColor: '#898989',

            // Background Color
            BGColor: '#FFFFFF',
            BGSColor: '#edf9ff',
            BGPColor: '#F8F8F8',
            BGVColor: '#A5A5A5',
            BGAColor: '#423148',

            // Font Color
            SFColor: '#14110F',
            SFSColor: '#a4a4a4',
            SFAColor: '#FFFFFF',
            SFVColor: '#BBBBBB',

            // Information Color
            EColor: '#E40101',
            EAColor: '#d33c3c',
            SCColor: '#94bf9b',
            WColor: '#FE9A00',

            // Special Color
            BTNColor: '#F7AF69',
            NoAuthorize: '#364654',
            White: '#FFFFFF',
        }
    },
    lenddi: {
        primaryC: '#014C2D',
        variantPrimaryC: '#014C2D',
        notification: '#ECECEC',
        notificationSeen: '#000000',
        secondaryC: '#32FAA5',
        backgroundColor: '#E6FFF0',
        variantSecondaryC: '#4147E8',
        alternateC: '#',
        backgroundC: '#FFFFFF',
        surfaceC: '#FFFFFF',
        errorC: '#E40101',
        onBackgroundC: '#A5A5A5',
        onBgVariantC: '#423148',
        onSurfaceC: '#14110F',
        onVariantSurfC: '#BBBBBB',
        onButtonC: '#F7AF69',
        grayLight: '#E1E1E1',
        backgroundP: '#FFFFFF',
        nameApp: 'Lenddi',
        sLogan: 'Luz verde para tu crédito',
        errorA: '#d33c3c',
        successC: '#94bf9b',
        onSurfaceV: '#a4a4a4',
        backgroundV: '#FFFFFF',

        // --------- Nuevos Colores para Temas
        PTheme: {
            // Interface
            PColor: '#014C2D',
            PVColor: '#014C2D',
            SColor: '#32FAA5',
            SVColor: '#4147E8',
            AColor: '#000000',
            PLColor: '#E1E1E1',
            PLAColor: '#898989',

            // Background Color
            BGColor: '#FFFFFF',
            BGSColor: '#FFFFFF',
            BGPColor: '#F8F8F8',
            BGVColor: '#A5A5A5',
            BGAColor: '#423148',

            // Font Color
            SFColor: '#14110F',
            SFSColor: '#a4a4a4',
            SFAColor: '#FFFFFF',
            SFVColor: '#BBBBBB',

            // Information Color
            EColor: '#E40101',
            EAColor: '#d33c3c',
            SCColor: '#94bf9b',

            // Special Color
            BTNColor: '#F7AF69',
            NoAuthorize: '#364654',
            White: '#FFFFFF',
        }
    },
    gym: {
        primaryC: '#111ABF',
        notification: '#ECECEC',
        notificationSeen: '#000000',
        variantPrimaryC: '#111ABF',
        secondaryC: '#32FAA5',
        backgroundColor: '#D5E7FF',
        variantSecondaryC: '#4147E8',
        alternateC: '#',
        backgroundC: '#FFFFFF',
        surfaceC: '#FFFFFF',
        errorC: '#E40101',
        onBackgroundC: '#A5A5A5',
        onBgVariantC: '#423148',
        onSurfaceC: '#14110F',
        onVariantSurfC: '#BBBBBB',
        onButtonC: '#F7AF69',
        grayLight: '#E1E1E1',
        backgroundP: '#FFFFFF',
        nameApp: 'G&M',
        sLogan: 'Servicios financieros',
        errorA: '#d33c3c',
        successC: '#94bf9b',
        onSurfaceV: '#a4a4a4',
        backgroundV: '#FFFFFF',

        // --------- Nuevos Colores para Temas
        PTheme: {
            // Interface
            PColor: '#111ABF',
            PVColor: '#111ABF',
            SColor: '#32FAA5',
            SVColor: '#4147E8',
            AColor: '#000000',
            PLColor: '#E1E1E1',
            PLAColor: '#898989',

            // Background Color
            BGColor: '#FFFFFF',
            BGSColor: '#FFFFFF',
            BGPColor: '#F8F8F8',
            BGVColor: '#A5A5A5',
            BGAColor: '#423148',

            // Font Color
            SFColor: '#14110F',
            SFSColor: '#a4a4a4',
            SFAColor: '#FFFFFF',
            SFVColor: '#BBBBBB',

            // Information Color
            EColor: '#E40101',
            EAColor: '#d33c3c',
            SCColor: '#94bf9b',

            // Special Color
            BTNColor: '#F7AF69',
            NoAuthorize: '#364654',
            White: '#FFFFFF',
        }
    },
    inversiones: {
        primaryC: '#FBBB01',
        variantPrimaryC: '#FBBB01',
        secondaryC: '#32FAA5',
        notification: '#ECECEC',
        notificationSeen: '#000000',
        backgroundColor: '#F7F7F7',
        variantSecondaryC: '#4147E8',
        alternateC: '#',
        backgroundC: '#FFFFFF',
        surfaceC: '#FFFFFF',
        errorC: '#E40101',
        onBackgroundC: '#A5A5A5',
        onBgVariantC: '#423148',
        onSurfaceC: '#14110F',
        onVariantSurfC: '#BBBBBB',
        onButtonC: '#F7AF69',
        grayLight: '#E1E1E1',
        backgroundP: '#FFFFFF',
        nameApp: 'Inversiones',
        sLogan: 'Inversiones & negocios',
        errorA: '#d33c3c',
        successC: '#94bf9b',
        onSurfaceV: '#a4a4a4',
        backgroundV: '#FFFFFF',

        // --------- Nuevos Colores para Temas
        PTheme: {
            // Interface
            PColor: '#FBBB01',
            PVColor: '#FBBB01',
            SColor: '#32FAA5',
            SVColor: '#4147E8',
            AColor: '#000000',
            PLColor: '#E1E1E1',
            PLAColor: '#898989',

            // Background Color
            BGColor: '#FFFFFF',
            BGSColor: '#FFFFFF',
            BGPColor: '#F8F8F8',
            BGVColor: '#A5A5A5',
            BGAColor: '#423148',

            // Font Color
            SFColor: '#14110F',
            SFSColor: '#a4a4a4',
            SFAColor: '#FFFFFF',
            SFVColor: '#BBBBBB',

            // Information Color
            EColor: '#E40101',
            EAColor: '#d33c3c',
            SCColor: '#94bf9b',

            // Special Color
            BTNColor: '#F7AF69',
            NoAuthorize: '#364654',
            White: '#FFFFFF',
        }
    },
    credinver: {
        primaryC: '#627732',
        variantPrimaryC: '#627732',
        notification: '#ECECEC',
        notificationSeen: '#000000',
        secondaryC: '#32FAA5',
        backgroundColor: '#D1DBBD',
        variantSecondaryC: '#4147E8',
        alternateC: '#',
        backgroundC: '#FFFFFF',
        surfaceC: '#FFFFFF',
        errorC: '#E40101',
        onBackgroundC: '#A5A5A5',
        onBgVariantC: '#423148',
        onSurfaceC: '#14110F',
        onVariantSurfC: '#BBBBBB',
        onButtonC: '#F7AF69',
        grayLight: '#E1E1E1',
        backgroundP: '#FFFFFF',
        nameApp: 'Credinver',
        sLogan: '',
        errorA: '#d33c3c',
        successC: '#94bf9b',
        onSurfaceV: '#a4a4a4',
        backgroundV: '#FFFFFF',

        // --------- Nuevos Colores para Temas
        PTheme: {
            // Interface
            PColor: '#243E38',
            PVColor: '#243E38',
            SColor: '#32FAA5',
            SVColor: '#4147E8',
            AColor: '#000000',
            PLColor: '#E1E1E1',
            PLAColor: '#898989',

            // Background Color
            BGColor: '#FFFFFF',
            BGSColor: '#FFFFFF',
            BGPColor: '#F8F8F8',
            BGVColor: '#A5A5A5',
            BGAColor: '#423148',

            // Font Color
            SFColor: '#14110F',
            SFSColor: '#a4a4a4',
            SFAColor: '#FFFFFF',
            SFVColor: '#BBBBBB',

            // Information Color
            EColor: '#E40101',
            EAColor: '#d33c3c',
            SCColor: '#94bf9b',

            // Special Color
            BTNColor: '#F7AF69',
            NoAuthorize: '#364654',
            White: '#FFFFFF',
        }
    },
    freend: {
        primaryC: '#1C0147',
        variantPrimaryC: '#1C0147',
        notification: '#ECECEC',
        notificationSeen: '#000000',
        secondaryC: '#32FAA5',
        backgroundColor: '#6732BD',
        variantSecondaryC: '#4147E8',
        alternateC: '#',
        backgroundC: '#FFFFFF',
        surfaceC: '#FFFFFF',
        errorC: '#E40101',
        onBackgroundC: '#A5A5A5',
        onBgVariantC: '#423148',
        onSurfaceC: '#14110F',
        onVariantSurfC: '#BBBBBB',
        onButtonC: '#F7AF69',
        grayLight: '#E1E1E1',
        backgroundP: '#FFFFFF',
        nameApp: 'Credinver',
        sLogan: '',
        errorA: '#d33c3c',
        successC: '#94bf9b',
        onSurfaceV: '#a4a4a4',
        backgroundV: '#FFFFFF',

        // --------- Nuevos Colores para Temas
        PTheme: {
            // Interface
            PColor: '#36136E',
            PVColor: '#243E38',
            SColor: '#32FAA5',
            SVColor: '#4147E8',
            AColor: '#000000',
            PLColor: '#E1E1E1',
            PLAColor: '#898989',

            // Background Color
            BGColor: '#FFFFFF',
            BGSColor: '#FFFFFF',
            BGPColor: '#F8F8F8',
            BGVColor: '#A5A5A5',
            BGAColor: '#423148',

            // Font Color
            SFColor: '#14110F',
            SFSColor: '#a4a4a4',
            SFAColor: '#FFFFFF',
            SFVColor: '#BBBBBB',

            // Information Color
            EColor: '#E40101',
            EAColor: '#d33c3c',
            SCColor: '#94bf9b',

            // Special Color
            BTNColor: '#F7AF69',
            NoAuthorize: '#364654',
            White: '#FFFFFF',
        }
    },
    maxcredit: {
        primaryC: '#002449',
        variantPrimaryC: '#031126',
        notification: '#ECECEC',
        notificationSeen: '#000000',
        secondaryC: '#32FAA5',
        backgroundColor: '#0072E6',
        variantSecondaryC: '#4147E8',
        alternateC: '#',
        backgroundC: '#FFFFFF',
        surfaceC: '#FFFFFF',
        errorC: '#E40101',
        onBackgroundC: '#A5A5A5',
        onBgVariantC: '#423148',
        onSurfaceC: '#14110F',
        onVariantSurfC: '#BBBBBB',
        onButtonC: '#F7AF69',
        grayLight: '#E1E1E1',
        backgroundP: '#FFFFFF',
        nameApp: 'Maxcredit',
        sLogan: '',
        errorA: '#d33c3c',
        successC: '#94bf9b',
        onSurfaceV: '#a4a4a4',
        backgroundV: '#FFFFFF',

        // --------- Nuevos Colores para Temas
        PTheme: {
            // Interface
            PColor: '#002449',
            PVColor: '#243E38',
            SColor: '#32FAA5',
            SVColor: '#4147E8',
            AColor: '#000000',
            PLColor: '#E1E1E1',
            PLAColor: '#898989',

            // Background Color
            BGColor: '#FFFFFF',
            BGSColor: '#FFFFFF',
            BGPColor: '#F8F8F8',
            BGVColor: '#A5A5A5',
            BGAColor: '#423148',

            // Font Color
            SFColor: '#14110F',
            SFSColor: '#a4a4a4',
            SFAColor: '#FFFFFF',
            SFVColor: '#BBBBBB',

            // Information Color
            EColor: '#E40101',
            EAColor: '#d33c3c',
            SCColor: '#94bf9b',

            // Special Color
            BTNColor: '#F7AF69',
            NoAuthorize: '#364654',
            White: '#FFFFFF',
        }
    },
    sofi: {
        primaryC: '#5941E0',
        variantPrimaryC: '#0450F6',
        notification: '#ECECEC',
        notificationSeen: '#000000',
        secondaryC: '#32FAA5',
        backgroundColor: '#0072E6',
        variantSecondaryC: '#4147E8',
        alternateC: '#',
        backgroundC: '#FFFFFF',
        surfaceC: '#FFFFFF',
        errorC: '#E40101',
        onBackgroundC: '#A5A5A5',
        onBgVariantC: '#423148',
        onSurfaceC: '#14110F',
        onVariantSurfC: '#BBBBBB',
        onButtonC: '#F7AF69',
        grayLight: '#E1E1E1',
        backgroundP: '#FFFFFF',
        nameApp: 'Sofi',
        sLogan: '',
        errorA: '#d33c3c',
        successC: '#94bf9b',
        onSurfaceV: '#a4a4a4',
        backgroundV: '#FFFFFF',

        // --------- Nuevos Colores para Temas
        PTheme: {
            // Interface
            PColor: '#5941E0',
            PVColor: '#243E38',
            SColor: '#32FAA5',
            SVColor: '#4147E8',
            AColor: '#000000',
            PLColor: '#E1E1E1',
            PLAColor: '#898989',

            // Background Color
            BGColor: '#FFFFFF',
            BGSColor: '#FFFFFF',
            BGPColor: '#F8F8F8',
            BGVColor: '#A5A5A5',
            BGAColor: '#423148',

            // Font Color
            SFColor: '#14110F',
            SFSColor: '#a4a4a4',
            SFAColor: '#FFFFFF',
            SFVColor: '#BBBBBB',

            // Information Color
            EColor: '#E40101',
            EAColor: '#d33c3c',
            SCColor: '#94bf9b',

            // Special Color
            BTNColor: '#F7AF69',
            NoAuthorize: '#364654',
            White: '#FFFFFF',
        }
    },
    zali: {
        primaryC: '#003478',
        backgroundColor: '#003478',
        variantPrimaryC: '#364654',
        notification: '#ECECEC',
        notificationSeen: '#000000',
        secondaryC: '#F15A24',
        variantSecondaryC: '#FFAC00',
        alternateC: '#',
        backgroundC: '#FFFFFF',
        surfaceC: '#FFFFFF',
        errorC: '#E40101',
        onBackgroundC: '#A5A5A5',
        onBgVariantC: '#423148',
        onSurfaceC: '#14110F',
        onVariantSurfC: '#BBBBBB',
        onButtonC: '#F7AF69',
        grayLight: '#E1E1E1',
        backgroundP: '#FFFFFF',
        nameApp: 'Zali',
        sLogan: '',
        errorA: '#d33c3c',
        successC: '#94bf9b',
        onSurfaceV: '#a4a4a4',
        backgroundV: '#FFFFFF',
        // --------- Nuevos Colores para Temas
        PTheme: {
            // Interface
            PColor: '#003478',
            PVColor: '#364654',
            SColor: '#F15A24',
            SVColor: '#FFAC00',
            AColor: '#000000',
            PLColor: '#E1E1E1',
            PLAColor: '#898989',

            // Background Color
            BGColor: '#FFFFFF',
            BGSColor: '#FFFFFF',
            BGPColor: '#FFFFFF',
            BGVColor: '#A5A5A5',
            BGAColor: '#423148',
            // Font Color
            SFColor: '#14110F',
            SFSColor: '#a4a4a4',
            SFAColor: '#FFFFFF',
            SFVColor: '#BBBBBB',

            // Information Color
            EColor: '#E40101',
            EAColor: '#d33c3c',
            SCColor: '#94bf9b',

            // Special Color
            BTNColor: '#F7AF69',
            NoAuthorize: '#364654',
            White: '#FFFFFF',
        }
    },
    libramoneda: {
        primaryC: '#0D47A1',
        notification: '#F5F5F5',
        notificationSeen: '#757575',
        variantPrimaryC: '#0D47A1',
        secondaryC: '#00C853',
        backgroundColor: '#13295C',
        variantSecondaryC: '#1A237E',
        alternateC: '#FF6D00',
        backgroundC: '#FFFFFF',
        surfaceC: '#FFFFFF',
        errorC: '#D50000',
        onBackgroundC: '#616161',
        onBgVariantC: '#424242',
        onSurfaceC: '#212121',
        onVariantSurfC: '#BDBDBD',
        onButtonC: '#FFA726',
        grayLight: '#E0E0E0',
        backgroundP: '#FAFAFA',
        nameApp: 'LIBRAMONEDA',
        sLogan: 'LibraMoneda',
        errorA: '#D32F2F',
        successC: '#388E3C',
        onSurfaceV: '#757575',
        backgroundV: '#FFFFFF',

        // --------- Nuevos Colores para Temas
        PTheme: {
            // Interface
            PColor: '#0D47A1',
            PVColor: '#FFFFFF',
            SColor: '#00C853',
            SVColor: '#1A237E',
            AColor: '#FF6D00',
            PLColor: '#E0E0E0',
            PLAColor: '#9E9E9E',

            // Background Color
            BGColor: '#FFFFFF',
            BGSColor: '#F5F5F5',
            BGPColor: '#E3F2FD',
            BGVColor: '#757575',
            BGAColor: '#424242',

            // Font Color
            SFColor: '#212121',
            SFSColor: '#757575',
            SFAColor: '#FFFFFF',
            SFVColor: '#BDBDBD',

            // Information Color
            EColor: '#D50000',
            EAColor: '#D32F2F',
            SCColor: '#388E3C',

            // Special Color
            BTNColor: '#FFA726',
            NoAuthorize: '#364654',
            White: '#FFFFFF',
        },
    },
    quickbank: {
        primaryC: '#1F1F1F',
        notification: '#F5F5F5',
        notificationSeen: '#757575',
        variantPrimaryC: '#1F1F1F',
        secondaryC: '#00C853',
        backgroundColor: '#101010',
        variantSecondaryC: '#101010',
        alternateC: '#FF6D00',
        backgroundC: '#FFFFFF',
        surfaceC: '#FFFFFF',
        errorC: '#D50000',
        onBackgroundC: '#616161',
        onBgVariantC: '#424242',
        onSurfaceC: '#212121',
        onVariantSurfC: '#BDBDBD',
        onButtonC: '#FFA726',
        grayLight: '#E0E0E0',
        backgroundP: '#FAFAFA',
        nameApp: 'QUICKBANK',
        sLogan: 'QuickBank',
        errorA: '#D32F2F',
        successC: '#388E3C',
        onSurfaceV: '#757575',
        backgroundV: '#FFFFFF',

        // --------- Nuevos Colores para Temas
        PTheme: {
            // Interface
            PColor: '#1F1F1F',
            PVColor: '#FFFFFF',
            SColor: '#00C853',
            SVColor: '#1A237E',
            AColor: '#FF6D00',
            PLColor: '#101010',
            PLAColor: '#9E9E9E',

            // Background Color
            BGColor: '#FFFFFF',
            BGSColor: '#F5F5F5',
            BGPColor: '#E3F2FD',
            BGVColor: '#757575',
            BGAColor: '#424242',

            // Font Color
            SFColor: '#212121',
            SFSColor: '#757575',
            SFAColor: '#FFFFFF',
            SFVColor: '#BDBDBD',

            // Information Color
            EColor: '#D50000',
            EAColor: '#D32F2F',
            SCColor: '#388E3C',

            // Special Color
            BTNColor: '#FFA726',
            NoAuthorize: '#364654',
            White: '#FFFFFF',
        },
    },
    finanzas: {
        primaryC: '#180F2B',
        notification: '#8481AE',
        notificationSeen: '#000000',
        variantPrimaryC: '#364654',
        backgroundColor: '#8481AE',
        secondaryC: '#FF6C1A',
        variantSecondaryC: '#FFAC00',
        alternateC: '#',
        backgroundC: '#FFFFFF',
        surfaceC: '#FFFFFF',
        errorC: '#E40101',
        errorA: '#d33c3c',
        successC: '#94bf9b',
        onBackgroundC: '#A5A5A5',
        onBgVariantC: '#423148',
        onSurfaceC: '#14110F',
        onSurfaceV: '#a4a4a4',
        onVariantSurfC: '#BBBBBB',
        onButtonC: '#F7AF69',
        grayLight: '#E1E1E1',
        backgroundP: '#FFFFFF',
        backgroundV: '#FFFFFF',
        nameApp: 'Mili',
        sLogan: 'Tu Crédito de Confianza',
        // --------- Nuevos Colores para Temas
        PTheme: {
            // Interface
            PColor: '#180F2B',
            PVColor: '#364654',
            SColor: '#F15A24',
            SVColor: '#FFAC00',
            AColor: '#000000',
            PLColor: '#E1E1E1',
            PLAColor: '#898989',
            // Background Color
            BGColor: '#FFFFFF',
            BGSColor: '#FFFFFF',
            BGPColor: '#FFFFFF',
            BGVColor: '#A5A5A5',
            BGAColor: '#423148',
            // Font Color
            SFColor: '#180F2B',
            SFSColor: '#a4a4a4',
            SFAColor: '#FFFFFF',
            SFVColor: '#BBBBBB',
            // Information Color
            EColor: '#E40101',
            EAColor: '#d33c3c',
            SCColor: '#94bf9b',
            WColor: '#FE9A00',
            // Special Color
            BTNColor: '#F7AF69',
            NoAuthorize: '#364654',
            White: '#FFFFFF',
        }
    },

}
const getTheme = () => {
    const selectedTheme = selectClientName();
    return selectedTheme === 'local' || selectedTheme === 'appqa' || selectedTheme === 'apprework' ? themes.mili : themes[selectedTheme]
}

export default getTheme()